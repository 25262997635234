import { graphql } from 'gatsby';
import React, { useState, useRef } from 'react';

import Layout from '../components/Layout';
import Seo from 'gatsby-plugin-wpgraphql-seo';
// Animation
import { AnimatePresence, motion } from 'framer-motion';
// Guide Card
import { GuideCard } from '../components/landing/guides/GuidesIndex';
// Elements
import { ChevronFont, CopyIcon } from '../components/elements/Icon';
import { Link } from 'gatsby';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Image from '../components/elements/Image';
import { TealGlow } from '../components/Glow';
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

export default function SingleGuide({ data: { wpGuide, wpPage, wp } }) {
  return (
    <Layout wp={wp} hideFooter={false}>
      <Seo post={wpGuide} title={wpGuide?.title} />
      <SingleGuideMasthead wpGuide={wpGuide} />
      <SingleGuideSummary summary={wpGuide?.postTypeSingleGuide?.summary} />
      <KeyTopics keyTopics={wpGuide?.postTypeSingleGuide?.keyTopics} />
      <RelatedDefinitions definitions={wpGuide?.postTypeSingleGuide?.relatedDefinitions} />
      <CTA override={wpGuide?.postTypeSingleGuide?.cta?.overrideDefaultCta} cta={wpGuide?.postTypeSingleGuide?.cta} />
      {wpGuide?.postTypeSingleGuide?.relatedGuides?.length > 0 && (
        <RelatedGuides relatedGuides={wpGuide?.postTypeSingleGuide?.relatedGuides} />
      )}
    </Layout>
  );
}

function SingleGuideMasthead({ wpGuide }) {
  const [isCopied, setIsCopied] = useState(0);
  const currentUrl = typeof window !== 'undefined' ? window?.location?.href : '';

  let triggerTooltip = () => {
    setIsCopied(1);
    setTimeout(() => {
      setIsCopied(0);
    }, 2000);
  };
  return (
    <section className="bg-navy text-white pt-32 lg:pt-[9.2rem] pb-10 md:pb-20 lg:pb-[7.25rem] relative overflow-hidden">
      <div className="container grid grid-cols-12 md:gap-x-8 gap-y-8 md:gap-y-0 relative z-10">
        {/* Content */}
        <div className="col-span-12 md:col-span-6 lg:col-span-5">
          <Link
            to="/guides/"
            className="inline-flex space-x-2 items-center t-15 mb-6 md:mb-8 lg:mb-[3.75rem] hover:text-teal transition-color duration-200"
          >
            <ChevronFont />
            <span>Back to previous page</span>
          </Link>
          <span
            className="mb-4 block md:mb-6 t-subheading uppercase font-semibold text-teal !font-body t-14 tracking-widest"
            dangerouslySetInnerHTML={{ __html: wpGuide?.postTypeSingleGuide?.masthead?.subheading }}
          />
          <h1 className="t-58 font-medium mb-3" dangerouslySetInnerHTML={{ __html: wpGuide?.title }} />
          <p
            className="t-18 max-w-[21rem]"
            dangerouslySetInnerHTML={{ __html: wpGuide?.postTypeSingleGuide?.masthead?.description }}
          />
        </div>
        {/* Buttons */}
        <div className="col-span-12 md:col-span-6 lg:col-span-7 flex flex-col items-start md:items-end md:justify-end space-y-5 md:space-y-0 md:flex-row md:space-x-6">
          <Link to={wpGuide?.postTypeSingleGuide?.masthead?.link?.url} className="btn is-blue">
            <span dangerouslySetInnerHTML={{ __html: wpGuide?.postTypeSingleGuide?.masthead?.link?.title }} />
          </Link>
          {typeof window !== 'undefined' && (
            <CopyToClipboard text={currentUrl} onCopy={() => triggerTooltip()}>
              <button className="btn is-white has-icon">
                <div className="w-4 h-4 flex items-center">
                  <CopyIcon />
                </div>
                <span dangerouslySetInnerHTML={{ __html: isCopied === 1 ? 'Link copied!' : 'Copy link!' }} />
              </button>
            </CopyToClipboard>
          )}
        </div>
      </div>
      {/* Glows */}
      <TealGlow
        classes={`absolute bottom-[-10%] md:bottom-[-20%] right-[5%] xl:right-[10%] opacity-[.7] w-[560px] md:w-[800px]  h-[560px] md:h-[800px]`}
        stopTwo={'20%'}
        stopThree={'60%'}
      />
      <TealGlow
        classes={`absolute bottom-[-25%] md:bottom-[-60%] inset-x-0 mx-auto opacity-[.7] w-[760px] md:w-[1000px] h-[760px] md:h-[1000px]`}
        stopTwo={'20%'}
        stopThree={'60%'}
      />
    </section>
  );
}

function SingleGuideSummary({ summary }) {
  return (
    <section className="py-10 md:py-16 lg:py-[7.5rem]">
      <div className="container">
        <div className="rounded-[0.625rem] border border-black/5 bg-white p-6 sm:p-8 lg:p-12 flex flex-col sm:flex-row sm:justify-between space-y-5 sm:space-y-0 sm:space-x-6 md:space-x-8 lg:space-x-12">
          <div className="shrink-0">
            <h2 className="text-h4 text-black font-medium" dangerouslySetInnerHTML={{ __html: 'Summary' }} />
          </div>
          <div className="post-prose max-w-[39.125rem]">
            <p className="text-black !mb-0" dangerouslySetInnerHTML={{ __html: summary }} />
          </div>
        </div>
      </div>
    </section>
  );
}

function KeyTopics({ keyTopics }) {
  const [activeTopic, setActiveTopic] = useState(0);
  return (
    <section className="pb-10 md:pb-16 lg:pb-[7.5rem]">
      <div className="container">
        <div className="max-w-[69rem] mx-auto flex flex-col sm:flex-row sm:justify-between space-y-5 sm:space-y-0 sm:space-x-6 md:space-x-8 lg:space-x-12">
          <div className="shrink-0">
            <div className="sm:sticky top-32">
              <h2 className="text-h4 text-black font-medium" dangerouslySetInnerHTML={{ __html: 'Key topics' }} />
            </div>
          </div>
          {/* Key Topics */}
          <div className="max-w-[37.375rem] w-full grid gap-y-5 md:gap-y-6">
            {keyTopics?.map((topic, l) => (
              <div key={l}>
                {/* Summary */}
                <button
                  type="button"
                  className={`border border-black/10 rounded-[0.625rem] w-full flex items-center space-x-3 md:space-x-5 lg:space-x-6 text-left p-3 group transition-colors duration-200 ${
                    activeTopic === l ? 'bg-blue text-white' : ' hover:bg-blue hover:text-white '
                  }`}
                  onClick={() => setActiveTopic(l)}
                >
                  {/* Icon */}
                  <div
                    className={`rounded-[.5rem] w-full max-w-[4.5rem] md:max-w-[5rem] lg:max-w-[6rem] aspect-square shrink-0 flex items-start sm:items-center justify-center p-4 md:p-5 lg:p-8 ${
                      activeTopic === l ? 'bg-white/10' : 'bg-whiteBlue/10 group-hover:bg-white/10'
                    }`}
                  >
                    <Image
                      image={topic?.icon}
                      className={`w-full h-full object-contain transition-colors duration-200 ${
                        activeTopic === l ? '' : 'invert group-hover:invert-0'
                      }`}
                      objectFit="contain"
                    />
                  </div>
                  {/* Heading */}
                  <div className="max-w-[16.875rem]">
                    <h3
                      className="t-20 font-body font-medium mb-1"
                      dangerouslySetInnerHTML={{ __html: topic?.heading }}
                    />
                    <p className="t-18" dangerouslySetInnerHTML={{ __html: topic?.description }} />
                  </div>
                </button>
                <AnimatePresence>
                  {activeTopic === l && (
                    <motion.div
                      key={l}
                      initial={{ height: 0 }}
                      animate={{ height: 'auto' }}
                      exit={{ height: 0 }}
                      transition={{
                        ease: 'easeInOut',
                        duration: 0.35,
                      }}
                      className="overflow-hidden"
                    >
                      <div
                        className="post-prose pt-6 md:pt-8 lg:pt-12"
                        dangerouslySetInnerHTML={{ __html: topic?.content }}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function RelatedDefinitions({ definitions }) {
  return (
    <section className="mb-10 md:mb-12 lg:mb-[4.375rem]">
      <div className="container">
        <div className="rounded-[0.625rem] border border-black/5 bg-white p-6 sm:p-8 lg:p-12 flex flex-col sm:flex-row sm:justify-between space-y-5 sm:space-y-0 sm:space-x-6 md:space-x-8 lg:space-x-12">
          <div className="shrink-0">
            <h2
              className="text-h4 text-black font-medium"
              dangerouslySetInnerHTML={{ __html: 'Related definitions' }}
            />
          </div>
          <div className="post-prose max-w-[39.125rem] grid gap-y-4 sm:grid-cols-2 sm:gap-x-6 md:gap-x-8 lg:gap-x-12">
            {definitions?.map((item, k) => (
              <Link
                key={k}
                to={item?.uri}
                className="rounded-[0.375rem] flex items-start space-x-3 decor-none p-[.375rem] group hover:bg-blue transition-colors duration-200"
              >
                <div className="rounded-[.25rem] w-[1.875rem] h-[1.875rem] aspect-square shrink-0 flex items-center justify-center bg-[#F7F9FF] group-hover:bg-[#456DFD] p-[0.375rem] transition-colors duration-200">
                  <span
                    className="font-light border-b border-navy block w-3/4 decor-none text-center text-navy !leading-[1] transition-colors duration-200 group-hover:text-white group-hover:border-white pb-[.1rem]"
                    dangerouslySetInnerHTML={{ __html: item?.title.charAt(0) }}
                  />
                </div>
                <span
                  className="t-16 font-bold decor-none text-navy !leading-[1.1] pt-[.3rem] transition-colors duration-200 group-hover:text-white"
                  dangerouslySetInnerHTML={{ __html: item?.title }}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function CTA({ override, cta }) {
  const ctaData = {
    subheading: override ? cta?.subheading : 'ready to launch',
    heading: override ? cta?.heading : 'Ready to discuss your fund launch?',
    link: {
      title: override ? cta?.link?.title : 'Ready to launch',
      url: override ? cta?.link?.url : '/contact/',
    },
  };

  return (
    <section className="my-10 md:my-12 lg:my-[4.375rem]">
      <div className="container">
        <div className="py-10 md:py-12 lg:py-[4.375rem] border-y border-black/10 max-w-[57.5rem] mx-auto text-center">
          <span
            className="mb-4 block md:mb-5 t-subheading uppercase font-semibold text-blue !font-body t-14 tracking-widest"
            dangerouslySetInnerHTML={{ __html: ctaData?.subheading }}
          />
          <h2
            className="t-52 font-medium mb-5 md:mb-8 lg:mb-10"
            dangerouslySetInnerHTML={{ __html: ctaData?.heading }}
          />
          <div>
            <Link to={ctaData?.link?.url} className="btn is-blue">
              <span dangerouslySetInnerHTML={{ __html: ctaData?.link?.title }} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

function RelatedGuides({ relatedGuides }) {
  const relatedSwiper = useRef(null);
  return (
    <section className="my-12 md:my-20 lg:my-[7.5rem]">
      <div className="container flex flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-8 md:space-x-12 lg:space-x-[6.5rem]">
        {/* Heading */}
        <div className="shrink-0">
          <span
            className="mb-4 block md:mb-5 t-subheading uppercase font-semibold text-blue !font-body t-14 tracking-widest"
            dangerouslySetInnerHTML={{ __html: 'tools' }}
          />
          <h2 className="t-52 font-medium mb-5 md:mb-8 lg:mb-10" dangerouslySetInnerHTML={{ __html: 'Next up' }} />
        </div>
        {/* Slides */}
        <div className="w-full">
          <Swiper
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerView={'auto'}
            centeredSlides={false}
            roundLengths={true}
            ref={relatedSwiper}
          >
            {relatedGuides?.map((guide, i) => (
              <>
                <SwiperSlide
                  key={i}
                  className="max-w-[18.75rem] md:max-w-[23.625rem] w-full overflow-hidden rounded-[.75rem] border border-black/10"
                >
                  <GuideCard guide={guide} noBorder teal />
                </SwiperSlide>
              </>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export const pageQuery = graphql`
  query Partner($id: String!) {
    wp {
      ...GeneratedWp
      header {
        globalUpdatedHeader {
          fieldGroupName
          headerUpdated {
            topLevelNavigation {
              link {
                title
                target
                url
              }
              addMenu
              subMenu {
                tabs {
                  heading
                  description
                  menuType
                  columnMenu {
                    columns {
                      heading
                      cards {
                        image {
                          ...Image
                        }
                        link {
                          title
                          url
                        }
                        description
                      }
                    }
                  }
                  rowMenu {
                    heading
                    cards {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                  resourcesMenu {
                    listSubheading
                    listItems {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                    }
                    cardsSubheading
                    cardItems {
                      thumbnail {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                }
              }
            }
            buttons {
              textLink {
                target
                title
                url
              }
              buttonLink {
                target
                title
                url
              }
            }
            socials {
              image {
                ...Image
              }
              link {
                target
                title
                url
              }
            }
          }
        }
      }
    }
    wpGuide(id: { eq: $id }) {
      title
      uri
      date
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          localFile {
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      postTypeSingleGuide {
        masthead {
          subheading
          description
          link {
            url
            title
          }
        }
        summary
        keyTopics {
          heading
          icon {
            altText
            publicUrl
            sourceUrl
            localFile {
              publicURL
            }
          }
          description
          content
        }
        relatedDefinitions {
          ... on WpWikiPost {
            slug
            title
            uri
          }
        }
        cta {
          overrideDefaultCta
          subheading
          heading
          link {
            url
            title
          }
        }
        relatedGuides {
          ... on WpGuide {
            id
            uri
            title
            featuredImage {
              node {
                altText
                publicUrl
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`;
